<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <v-app-bar flat dense>
            <v-toolbar-title
              >DUT Configuration
              {{ editMode ? "- " + dutConfig.name : "" }}</v-toolbar-title
            >
          </v-app-bar>

          <v-form ref="form" @submit.prevent="onSubmit">
            <v-container fluid>
              <v-sheet class="mx-auto mb-5" outlined>
                <v-container class="py-5" fluid>
                  <v-alert
                    v-if="editMode && testpointFileContainer"
                    dark
                    color="secondary"
                    class="mb-10 text-center"
                  >
                    <v-icon large dark class="mr-2">mdi-alert</v-icon>
                    To upload a new test point file, create a new DutConfig.
                  </v-alert>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="DUT Config Name *"
                        name="dutconfigName"
                        type="text"
                        prepend-icon="mdi-rename-box"
                        autocomplete="off"
                        v-model.lazy="dut_formdata.name"
                        :rules="rules.requireCheck"
                        :disabled="!isInitialState && !isAdmin"
                      />
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        label="PCB Thickness *"
                        name="pcbThickness"
                        type="number"
                        prepend-icon="mdi-apps-box"
                        v-model.lazy="dut_formdata.pcb_thickness"
                        :rules="rules.requireCheck"
                        :disabled="!isInitialState && !isAdmin"
                        :onInput="onInputString()"
                      />
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        label="PCB Width"
                        name="pcbWidth"
                        type="number"
                        prepend-icon="mdi-apps-box"
                        v-model.lazy="dut_formdata.pcb_x"
                        :rules="rules.requireCheck"
                        :disabled="!isInitialState && !isAdmin"
                        :onInput="onInputString()"
                      />
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        label="PCB Height"
                        name="pcbHeight"
                        type="number"
                        prepend-icon="mdi-apps-box"
                        v-model.lazy="dut_formdata.pcb_y"
                        :rules="rules.requireCheck"
                        :disabled="!isInitialState && !isAdmin"
                        :onInput="onInputString()"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <GerberFileChip
                        v-if="testpointFileContainer"
                        :fileList="testpointFileContainer"
                        removeAllText="Clear"
                        :allowDeleteAll="true"
                        @removeAll="removeAllDut"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-sheet
                        class="ml-8 py-2 cursor-text"
                        :class="
                          !testpointFileContainer && !testpoint_file
                            ? 'borderError'
                            : 'currentColor'
                        "
                        @click.stop="openParserModal"
                      >
                        <v-row>
                          <v-col>
                            <v-icon
                              class="ml-n8"
                              :color="
                                !testpointFileContainer && !testpoint_file
                                  ? ''
                                  : ''
                              "
                              >mdi-paperclip</v-icon
                            >
                            <label
                              v-if="!testpoint_file"
                              class="ml-5 subtitle-1"
                              :class="
                                !testpointFileContainer && !testpoint_file
                                  ? 'grey--text'
                                  : !isInitialState && !isAdmin
                                  ? 'grey--text'
                                  : ''
                              "
                            >
                              Click here to upload Test Point file *
                            </label>
                            <span v-else>
                              <GerberFileChip
                                prefixText="Uploaded File"
                                removeAllText="Clear"
                                :editMode="editMode"
                                :fileList="[{ file: testpoint_file.name }]"
                                :allowDelete="false"
                                :allowDownload="false"
                                :allowDeleteAll="false"
                                @removeAll="removeAllDut"
                                class="ml-5"
                              />
                            </span>
                            <v-divider class="currentColor" />
                          </v-col>
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </v-row>
                  <v-row dense class="my-5">
                    <v-col cols="12">
                      <gerber-file-chip
                        v-if="gerberFileContainer && zippedGerbers.length === 0"
                        :editMode="editMode"
                        :fileList="gerberFileContainer"
                        removeAllText="Clear"
                        :allowDelete="true"
                        :allowDownload="true"
                        :allowDeleteAll="true"
                        @download="downloadFile"
                        @removeFile="removeFile"
                        @removeAll="removeAllFile"
                        class="ml-3"
                      />
                      <v-chip
                        v-if="
                          (zippedGerberContainer || gerberFileContainer) &&
                            zippedGerbers.length === 0
                        "
                        class="ma-2"
                        color="warning"
                        text-color="white"
                        small
                        @click="downloadZippedGerber(zippedGerberContainer)"
                      >
                        <v-icon left>
                          mdi-alert-circle-outline
                        </v-icon>
                        {{ `Download All Gerbers` }}
                      </v-chip>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-sheet
                        class="ml-8 cursor-text"
                        @click.stop="
                          gerberModal = isInitialState || isAdmin ? true : false
                        "
                      >
                        <v-row>
                          <v-col>
                            <v-icon class="ml-n8">mdi-paperclip</v-icon>
                            <label
                              v-if="zippedGerbers.length === 0"
                              class="ml-1 font-weight-light"
                              :class="
                                !isInitialState && !isAdmin ? 'grey--text' : ''
                              "
                            >
                              Click here to upload zipped Gerber Files *
                            </label>
                            <span v-else>
                              <gerber-file-chip
                                prefixText="Uploaded File"
                                :editMode="editMode"
                                :fileList="zippedGerbers"
                                :allowDelete="false"
                                :allowDownload="false"
                                :allowDeleteAll="true"
                                @download="downloadFile"
                                @removeFile="removeFile"
                                @removeAll="removeAllZip"
                                class="ml-n5"
                              />
                            </span>
                            <v-divider class="currentColor" />
                          </v-col>
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <gerber-file-chip
                        v-if="editMode && stepFileContainer"
                        color="warning"
                        :editMode="editMode"
                        :fileList="stepFileContainer"
                        :allowDelete="false"
                        @download="downloadFile"
                      />
                      <base-input-popover uiGetter="ui/step_pop">
                        <v-file-input
                          show-size
                          counter
                          label="STEP File"
                          v-model="step_file"
                          :rules="rules.requireSize"
                          accept=".step, .stp"
                          hint="File types: .step, .stp"
                          persistent-hint
                          color="warning"
                          :class="
                            isInitialState || isAdmin ? 'admin-label' : ''
                          "
                          v-show="isAdmin"
                          :disabled="!isInitialState && !isAdmin"
                        >
                          <template v-slot:selection="{ text }">
                            <v-chip small label color="warning">
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                        <template v-slot:subtitle>
                          <div>
                            STEP files are a useful 3D file type due to their
                            precision and customizability.
                          </div>
                        </template>
                        <template v-slot:sublink>
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <a
                                href="https://all3dp.com/2/step-file-format-simply-explained/"
                                target="_blank"
                                class="no-underline red--text"
                                v-on="on"
                                >Read more...</a
                              >
                            </template>
                            Opens in new window
                          </v-tooltip>
                        </template>
                      </base-input-popover>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <gerber-file-chip
                        v-if="editMode && designFileContainer"
                        :editMode="editMode"
                        :fileList="designFileContainer"
                        :allowDelete="false"
                        @download="downloadFile"
                      />
                      <v-file-input
                        show-size
                        counter
                        label="Supporting Files"
                        v-model="design_file"
                        :rules="rules.requireSize"
                        hint="You may upload other supporting files that can help FixturFab build your Test Fixture"
                        persistent-hint
                        :disabled="!isInitialState && !isAdmin"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 3"
                            color="primary"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 3"
                            class="overline grey--text text--darken-3 mx-2"
                          >
                            +{{ design_file.length - 3 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        label="Description"
                        prepend-icon="mdi-card-text"
                        v-model.lazy="dut_formdata.description"
                        rows="1"
                        auto-grow
                        :disabled="!isInitialState && !isAdmin"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
              <v-row class="mx-auto">
                <v-col cols="12">
                  <v-checkbox
                    v-model="checkbox"
                    label="Show Popover"
                    @change="toggleCheck($event)"
                    dense
                    hide-details
                    class="pb-2"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row class="mx-auto">
                <v-col cols="12" md="3">
                  <v-btn
                    v-if="editMode"
                    block
                    color="warning"
                    class="mr-5"
                    @click="onCancel"
                  >
                    {{ isInitialState || isAdmin ? "Cancel" : "Close" }}
                  </v-btn>
                </v-col>
                <v-col cols="12" md="3" offset-md="3">
                  <v-btn
                    v-if="!editMode"
                    block
                    color="warning"
                    class="mr-5"
                    @click="resetForm"
                  >
                    Reset
                  </v-btn>
                </v-col>
                <v-col cols="12" md="3">
                  <v-btn
                    v-if="
                      !editMode || (editMode && (isInitialState || isAdmin))
                    "
                    block
                    type="submit"
                    color="secondary"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <FileParserModal
      v-if="parserModal"
      ref="dut_parser"
      :dialog="parserModal"
      :parsedJsonBlob="parsedJsonBlob"
      :parsedTestPoints="parsedTestPoints"
      :parsedGuidePins="parsedGuidePins"
      :parsedPressurePins="parsedPressurePins"
      :parsedHoles="parsedHoles"
      :parsedDutData="parsedDutData"
      :parsedMissingHeaders="parsedMissingHeaders"
      :testpointLayers="testpointLayers"
      :testpointTypes="testpointTypes"
      :savedDut="testpointFile"
      :pcbStackup="pcbStackup"
      :dutUnits="dutUnits"
      :isAdmin="isAdmin"
      :dutReport="dutFieldDescriptions.dut_report"
      :altiumReport="dutFieldDescriptions.altium_report"
      :experimentalFiles="dutFieldDescriptions.experimental_files"
      @parseTestPoint="parseTestPoint"
      @saveParsedTestPoints="saveParsedTestPoints"
      @initCachedParsedData="initCachedParsedData"
      @closeModal="closeParserModal"
    />
    <FileUploadModal
      v-if="gerberModal"
      :dialog="gerberModal"
      :gerbers="gerberFiles"
      @saveGerbers="saveGerbers"
      @closeModal="closeGerberModal"
    />
  </v-container>
</template>
<script>
export default {
  name: "DutConfigForm",
  props: {
    fdID: String,
    editMode: Boolean,
    isInitialState: {
      type: Boolean,
      default: true,
    },
    dutConfig: {
      type: Object,
      default: null,
    },
    gerberFiles: {
      type: Array,
      default: null,
    },
    parsedJsonBlob: {
      type: Object,
      default: null,
    },
    parsedTestPoints: {
      type: Array,
      default: null,
    },
    parsedGuidePins: {
      type: Array,
      default: null,
    },
    parsedPressurePins: {
      type: Array,
      default: null,
    },
    parsedHoles: {
      type: Array,
      default: null,
    },
    parsedDutData: {
      type: Object,
      default: null,
    },
    parsedMissingHeaders: {
      type: Array,
      default: null,
    },
    testpointLayers: {
      type: Array,
      default: null,
    },
    testpointTypes: {
      type: Array,
      default: null,
    },
    pcbStackup: {
      type: Object,
      default: null,
    },
    dutUnits: {
      type: Array,
      default: null,
    },
    dutFieldDescriptions: {
      type: Object,
      default: null,
    },
  },
  components: {
    GerberFileChip: () => import("./GerberFileChip.vue"),
    FileUploadModal: () => import("./FileUploadModal.vue"),
    FileParserModal: () => import("@/modules/quotes/components/FileParserModal.vue"),
  },
  data() {
    return {
      dut_formdata: {
        name: this.editMode && this.dutConfig ? this.dutConfig.name : null,
        pcb_thickness:
          this.editMode && this.dutConfig ? this.dutConfig.pcb_thickness : 1.6,
        pcb_x: this.editMode && this.dutConfig ? this.dutConfig.pcb_x : null,
        pcb_y: this.editMode && this.dutConfig ? this.dutConfig.pcb_y : null,
        description:
          this.editMode && this.dutConfig ? this.dutConfig.description : null,
      },
      testpoint_file: null,
      step_file: null,
      design_file: null,
      gerber_files: null,
      //svg_file: null,
      checkbox: true,
      rules: {
        requireCheck: [(v) => !!v || "Input is required"],
        requireSize: [(v) => !v || !!v || "File size should be less than 20MB"],
      },
      gerberModal: false,
      zippedGerbers: [],
      parserModal: false,
    };
  },
  computed: {
    userID() {
      return this.$store.getters["auth/user"].pk;
    },
    isAdmin() {
      return this.$store.state.auth.user.is_staff;
    },
    gerberFileContainer() {
      return this.gerberFiles && this.gerberFiles.length > 0
        ? this.gerberFiles.map((element) => ({
            pk: element.pk,
            url: element.file,
            file: element.file.split("?")[0].split("/").pop(),
          }))
        : null;
    },
    testpointFileContainer() {
      return this.dutConfig?.testpoint_file
        ? [
            {
              url: this.dutConfig.testpoint_file,
              file: this.dutConfig.testpoint_file.split("?")[0].split("/").pop(),
            },
          ]
        : null;
    },
    stepFileContainer() {
      return this.dutConfig?.step_file
        ? [
            {
              url: this.dutConfig.step_file,
              file: this.dutConfig.step_file.split("?")[0].split("/").pop(),
            },
          ]
        : null;
    },
    designFileContainer() {
      return this.dutConfig?.design_file
        ? [
            {
              url: this.dutConfig.design_file,
              file: this.dutConfig.design_file.split("?")[0].split("/").pop(),
            },
          ]
        : null;
    },
    zippedGerberContainer() {
      return this.dutConfig?.zipped_gerber_files
        ? {
            url: this.dutConfig.zipped_gerber_files,
            file: this.dutConfig.zipped_gerber_files.split("?")[0].split("/").pop(),
          }
        : null;
    },
    testpointFile() {
      return this.dutConfig
        ? this.dutConfig.testpoint_file
        : null;
    },
  },
  methods: {
    hybridRules({ required, fieldHasValue }) {
      const rules = [];
      if (required && !fieldHasValue) {
        const rule = (v) => !!v || "Input is required";
        rules.push(rule);
      }
      return rules;
    },
    async onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.isAdmin && this.zippedGerbers.length === 0) {
        this.$store.commit("ui/SNACK_BAR", "Fill all required fields.");
        return;
      }
      let inputData = new FormData();
      Object.keys(this.dut_formdata).forEach((key) => {
        let value = this.dut_formdata[key];
        if (value) {
          inputData.append(key, value);
        }
      });
      if (!this.editMode) {
        inputData.append("user", this.userID);
        inputData.append("project", this.$route.params.id);
        inputData.append("fixture_design", this.fdID);
      }
      if (this.testpoint_file) {
        inputData.append("testpoint_file", this.testpoint_file);
      }
      if (this.step_file) {
        inputData.append("step_file", this.step_file);
      }
      if (this.design_file) {
        inputData.append("design_file", this.design_file);
      }
      this.$emit("saveData", {
        inputData,
      });
    },
    resetForm() {
      this.$refs.form.reset();
    },
    onCancel() {
      this.$refs.form.reset();
      this.$emit("backToPrevious");
    },
    toggleCheck(e) {
      this.$store.commit("ui/toggleAll", e);
    },
    downloadFile(payload) {
      this.$emit("downloadGerber", payload);
    },
    downloadZippedGerber(payload) {
      if (payload) {
        this.$emit("downloadGerber", payload);
        return;
      }
      this.gerberFileContainer &&
        this.createGerberZipFile(this.gerberFileContainer);
    },
    async createGerberZipFile(payload) {
      this.$emit("createAndDownloadGerber", payload);
    },
    removeFile(pk) {
      this.$emit("deleteGerber", pk);
    },
    removeAllFile() {
      this.$emit("deleteAllGerber");
    },
    removeAllZip() {
      this.zippedGerbers.splice(0);
    },
    removeAllDut() {
      this.$emit("deleteAllDut");
    },
    saveGerbers({ actionPayload, zipped_file }) {
      this.zippedGerbers = actionPayload.map((element) => ({
        file: element.filename,
        gerber: element.gerber,
      }));
      this.$emit("onSaveGerberFiles", {
        gerberData: {
          user: this.dutConfig.user,
          project: this.$route.params.id,
          fixture_design: this.fdID,
          gerberFiles: [...this.zippedGerbers],
        },
        zipped_file,
      });
      this.closeGerberModal();
      this.removeAllZip();
    },
    closeGerberModal() {
      this.gerberModal = false;
    },
    async openParserModal() {
      this.parserModal = true;
      if (this.testpointFile) {
        await this.parseTestPoint(await this.createFile(this.testpointFile));
      }
    },
    closeParserModal() {
      this.parserModal = false;
    },
    clearUploadedTestPoint() {
      this.testpoint_file = null;
    },
    parseTestPoint(payload) {
      let parseData = new FormData();
      parseData.append("testpoint_file", payload);
      parseData.append(
        "project_pk", this.fdID
      );
      const actionPayload = {
        data: parseData,
        dut_id: this.dutConfig.pk,
      };
      this.$emit("parseTestPoint", actionPayload);
    },
    saveParsedTestPoints({ testpoint_file, dut_settings, parsed_json_blob }) {
      console.log(testpoint_file, dut_settings);
      this.testpoint_file = testpoint_file;
      this.dut_formdata.pcb_y = dut_settings.dut_length;
      this.dut_formdata.pcb_x = dut_settings.dut_width;
      this.dut_formdata.pcb_thickness = dut_settings.dut_height;
      this.$emit("onSaveParsedDUT", { testpoint_file, dut_settings, parsed_json_blob });
    },
    async createFile(url) {
      const response = await fetch(url);
      const data = await response.blob();
      const metadata = {
        type: "text/csv",
      };
      return new File([data], url.split("?")[0].split("/").pop(), metadata);
    },
    updateConfig2d() {
      this.$refs["dut_parser"] && this.$refs["dut_parser"].initConfig2d();
    },
    initTestPointFile() {
      this.$refs["dut_parser"] && this.$refs["dut_parser"].initTestPointFile();
    },
    initCachedParsedData() {
      this.$store.commit("dutconfigs/INIT_PARSED_FILES");
    },
    onInputString() {
      return "if (this.value > 2147483647) this.value = 2147483647; if (this.value < 0) this.value = 0;"
    }
  },
  created() {
    this.$store.commit("ui/toggleAll", this.checkbox);
  },
};
</script>
<style scoped>
.admin-label /deep/ label {
  color: #950952 !important;
}
.currentColor {
  border-color: #7d9495;
}
</style>
